var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppRow',{attrs:{"permissions":['SLOTS_ADMINISTRATION']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var profile = ref.profile;
return [_c('p',{staticStyle:{"display":"none"}},[_vm._v(" "+_vm._s(profile)+" ")]),_c('v-col',{staticClass:"text-center text-h5",attrs:{"cols":"12"}},[_vm._v(" Slots ")]),_c('v-col',{staticClass:"text-center text-h5",attrs:{"cols":"12"}},[_c('ApolloQuery',{attrs:{"query":require('../graphql/queries/getSlotsListAdmin.gql'),"variables":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var withdrawError = ref_result.error;
var userWithdraws = ref_result.data;
var withdrawIsLoading = ref.isLoading;
return [(withdrawError)?_c('AppNotification',{attrs:{"message":withdrawError.message}}):(withdrawIsLoading === 1)?_c('v-card',{attrs:{"loading":withdrawIsLoading === 1,"flat":""}}):(userWithdraws && userWithdraws.getSlotsListAdmin)?_c('v-card',{attrs:{"flat":""}},[(userWithdraws.getSlotsListAdmin)?_c('v-data-table',{attrs:{"headers":_vm.withdrawHeaders,"items":userWithdraws.getSlotsListAdmin || [],"items-per-page":_vm.withdrawsItemsPerPage,"disable-sort":""},scopedSlots:_vm._u([{key:"item.iconUrl",fn:function(ref){
var item = ref.item;
return [(!!item.iconUrl)?_c('img',{attrs:{"src":_vm.base_url + item.iconUrl,"width":"100px"}}):_vm._e()]}},{key:"item.winsSum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.betsSum ? item.winsSum / item.betsSum : 0)+" ")]}},{key:"item.sid",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"text-none",attrs:{"depressed":"","height":"36","large":""},on:{"click":function($event){return _vm.show_alert(item)}}},[_vm._v(" Подробнее ")])]}}],null,true)}):_vm._e()],1):_c('AppNotification',{attrs:{"message":"Нет результата :("}})]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"1200px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.slotInfo.name)+" ("+_vm._s(_vm.slotInfo.developer)+")")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-switch',{attrs:{"label":"Состояние"},model:{value:(_vm.slotEnabled),callback:function ($$v) {_vm.slotEnabled=$$v},expression:"slotEnabled"}})],1),_c('v-row',[_c('v-text-field',{attrs:{"hide-details":"auto","label":"Позиция"},model:{value:(_vm.slotRank),callback:function ($$v) {_vm.slotRank=_vm._n($$v)},expression:"slotRank"}})],1),_c('v-row',[_c('v-select',{attrs:{"items":_vm.allListings,"menu-props":{ maxHeight: '400' },"label":"Select","multiple":"","hint":"Категории","persistent-hint":""},model:{value:(_vm.selectedListings),callback:function ($$v) {_vm.selectedListings=$$v},expression:"selectedListings"}})],1),_vm._l((_vm.selectedListings),function(listingId){return _c('v-row',{key:listingId},[_c('v-col',[_vm._v(_vm._s(listingId))]),_c('v-col',[_c('v-text-field',{attrs:{"hide-details":"auto","label":"Позиция"},model:{value:(_vm.listingRanks[listingId]),callback:function ($$v) {_vm.$set(_vm.listingRanks, listingId, _vm._n($$v))},expression:"listingRanks[listingId]"}})],1)],1)})],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Закрыть ")]),_c('v-btn',{attrs:{"color":"green","text":""},on:{"click":_vm.updateSlotInfo}},[_vm._v(" Сохранить ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }