<template>
  <AppRow :permissions="['SLOTS_ADMINISTRATION']">
    <template v-slot="{ profile }">
      <p style="display: none">
        {{ profile }}
      </p>
      <v-col
        class="text-center text-h5"
        cols="12"
      >
        Slots
      </v-col>
      <v-col
        class="text-center text-h5"
        cols="12"
      >
        <ApolloQuery
          :query="require('../graphql/queries/getSlotsListAdmin.gql')"
          :variables="{}"
        >
          <template
            v-slot="{ result: { error: withdrawError, data: userWithdraws }, isLoading: withdrawIsLoading }"
          >
            <!-- Error -->
            <AppNotification
              v-if="withdrawError"
              :message="withdrawError.message"
            />

            <!-- Result -->
            <v-card
              v-else-if="withdrawIsLoading === 1"
              :loading="withdrawIsLoading === 1"
              flat
            />

            <!-- Result -->
            <v-card
              v-else-if="userWithdraws && userWithdraws.getSlotsListAdmin"
              flat
            >
              <v-data-table
                v-if="userWithdraws.getSlotsListAdmin"
                :headers="withdrawHeaders"
                :items="userWithdraws.getSlotsListAdmin || []"
                :items-per-page="withdrawsItemsPerPage"
                disable-sort
              >
                <template v-slot:[`item.iconUrl`]="{ item }">
                  <img
                    v-if="!!item.iconUrl"
                    :src="base_url + item.iconUrl"
                    width="100px"
                  >
                </template>
                <template #item.winsSum="{ item }">
                  {{ item.betsSum ? item.winsSum / item.betsSum : 0 }}
                </template>
                <template #item.sid="{ item }">
                  <v-btn
                    class="text-none"
                    depressed
                    height="36"
                    large
                    @click="show_alert(item)"
                  >
                    Подробнее
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>

            <!-- No result -->
            <AppNotification
              v-else
              message="Нет результата :("
            />
          </template>
        </ApolloQuery>
      </v-col>

      <v-dialog
        v-model="dialog"
        max-width="1200px"
        persistent
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ slotInfo.name }} ({{ slotInfo.developer }})</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-switch
                  v-model="slotEnabled"
                  label="Состояние"
                />
              </v-row>
              <v-row>
                <v-text-field
                  v-model.number="slotRank"
                  hide-details="auto"
                  label="Позиция"
                />
              </v-row>
              <v-row>
                <v-select
                  v-model="selectedListings"
                  :items="allListings"
                  :menu-props="{ maxHeight: '400' }"
                  label="Select"
                  multiple
                  hint="Категории"
                  persistent-hint
                />
              </v-row>

              <v-row
                v-for="listingId in selectedListings"
                :key="listingId"
              >
                <v-col>{{ listingId }}</v-col>
                <v-col>
                  <v-text-field
                    v-model.number="listingRanks[listingId]"
                    hide-details="auto"
                    label="Позиция"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />

            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              Закрыть
            </v-btn>

            <v-btn
              color="green"
              text
              @click="updateSlotInfo"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </AppRow>
</template>

<script>
import AppRow from '@/components/AppRow.vue';
import AppNotification from '@/components/AppNotification.vue';

import getCategories from '@/graphql/queries/getCategories.gql';
import updateSlotInfo from '@/graphql/mutations/updateSlotInfo.gql';

export default {
  components: {
    AppRow,
    AppNotification,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_MAIN_SITE_URL,
      withdrawHeaders: [
        { text: 'Иконка:', value: 'iconUrl', class: 'white--text text-subtitle-2' },
        { text: 'ID:', value: 'id', class: 'white--text text-subtitle-2' },
        { text: 'Название:', value: 'name', class: 'white--text text-subtitle-2' },
        { text: 'Провайдер:', value: 'developer', class: 'white--text text-subtitle-2' },
        { text: 'Путь:', value: 'route', class: 'white--text text-subtitle-2' },
        { text: 'Есть демо:', value: 'haveDemo', class: 'white--text text-subtitle-2' },
        { text: 'Сумма ставок:', value: 'betsSum', class: 'white--text text-subtitle-2' },
        { text: 'RTP:', value: 'winsSum', class: 'white--text text-subtitle-2' },
        { text: 'Состояние:', value: 'enabled', class: 'white--text text-subtitle-2' },
        { text: '', value: 'sid', class: 'white--text text-subtitle-2' },
      ],
      dialog: false,
      pageIndex: 1,
      itemsPerPage: 5,
      slotInfo: {},
      slotId: undefined,
      slotRank: 0,
      slotEnabled: false,
      listingsIds: [],
      selectedListings: [],
      listingRanks: {},
      allListings: [],
    };
  },

  mounted() {
    this.$apollo.query({
      query: getCategories,
      variables: {},
    }).then(it => {
      this.allListings = it.data.getSlotsCategories.map(itt => itt.name);
    });
  },
  methods: {
    show_alert(item) {
      this.slotInfo = item;
      this.dialog = true;

      this.slotId = item.id;
      this.slotEnabled = item.enabled;
      this.slotRank = item.rank;
      this.listingsIds = item.listings.map(it => it.id);
      this.selectedListings = this.listingsIds;

      item.listings.forEach(it => {
        this.listingRanks[it.id] = it.rank;
      });
    },
    updateSlotInfo() {
      const listings = [];

      this.selectedListings.forEach(item => listings.push({
        id: item,
        rank: this.listingRanks[item],
      }));

      this.$apollo.mutate({
        mutation: updateSlotInfo,
        variables: {
          id: this.slotId,
          enabled: this.slotEnabled,
          rank: this.slotRank,
          listings,
        },
      }).then(it => {
        console.log(it);
        this.dialog = false;
      });
    },
  },
};
</script>
